var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    _vm._g({ staticClass: "list-view-item overflow-hidden" }, _vm.$listeners),
    [
      _c("template", { slot: "no-body" }, [
        _c("div", { staticClass: "vx-row item-details no-gutter" }, [
          _c(
            "div",
            {
              staticClass:
                "vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center cursor-pointer",
              on: { click: _vm.navigate_to_detail_view },
            },
            [
              _c("img", {
                staticClass: "grid-view-img p-4",
                attrs: { src: _vm.item.image, alt: _vm.item.name },
              }),
            ]
          ),
          _c("div", { staticClass: "vx-col sm:w-1/2" }, [
            _c(
              "div",
              { staticClass: "p-4 pt-6" },
              [
                _vm._t("item-meta", function () {
                  return [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "item-name font-semibold mb-1 hover:text-primary cursor-pointer",
                        on: { click: _vm.navigate_to_detail_view },
                      },
                      [_vm._v(_vm._s(_vm.item.name))]
                    ),
                    _c("p", { staticClass: "text-sm mb-4" }, [
                      _vm._v("By "),
                      _c(
                        "span",
                        { staticClass: "font-semibold cursor-pointer" },
                        [_vm._v(_vm._s(_vm.item.brand))]
                      ),
                    ]),
                    _c("p", { staticClass: "item-description text-sm" }, [
                      _vm._v(_vm._s(_vm.item.description)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "vx-col sm:w-1/4 w-full flex items-center sm:border border-0 border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0",
            },
            [
              _c(
                "div",
                { staticClass: "p-4 flex flex-col w-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-warning flex self-end border border-solid border-warning py-1 px-2 rounded",
                    },
                    [
                      _c("span", { staticClass: "text-sm mr-1" }, [
                        _vm._v(_vm._s(_vm.item.rating)),
                      ]),
                      _c("feather-icon", {
                        attrs: { icon: "StarIcon", svgClasses: "h-4 w-4" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "my-6" }, [
                    _c("h5", { staticClass: "font-bold text-center" }, [
                      _vm._v("$" + _vm._s(_vm.item.price)),
                    ]),
                    _vm.item.free_shipping
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-grey flex items-start justify-center mt-1",
                          },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "ShoppingCartIcon",
                                svgClasses: "w-4 h-4",
                              },
                            }),
                            _c("span", { staticClass: "text-sm ml-2" }, [
                              _vm._v("Free Shipping"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._t("action-buttons"),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }